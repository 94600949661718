<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}">
</p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-navbar-style-one></app-navbar-style-one>

    <div class="m-lg-5 m-md-2 m-sm-2 m-2">
        <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/dashboard">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <ng-container *ngIf="valueObject.length > 0">
        <app-stats [counter]="valueObject[0]"></app-stats>
    </ng-container>
    <!-- End -->

    <p-table #dt [value]="customerBookingList" dataKey="id" [rowHover]="true" [rows]="50"
            responsiveLayout="scroll" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [filterDelay]="0" [globalFilterFields]="['downLineName', 'yourId', 'customerName', 'contactNumber', 'emailAddress', 'bookingQuantity', 'status', 'transactionalID']">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left d-flex align-items-center">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Global Search" [(ngModel)]="filterval" />
                            <p-calendar appendTo="body" class="ml-2" dateFormat="dd-mm-yy" placeholder="Booking Date"
                                 [(ngModel)]="dateFilterVal"
                                (onSelect)="searchFilter($event)">
                            </p-calendar>
                            <p-button type="button" class="ml-2" (click)="reset(dt)" icon="bx bx-reset" pTooltip="Reset" tooltipPosition="bottom"></p-button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mx-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>S. No.</th>
                    <th *ngIf="this.user?.upLineName == 'Admin'">Action</th>
                    <!-- <th>Order Id</th> -->
                    <th style="min-width: 150px;">Booking Date</th>
                    <th>Payment Mode</th>
                    <th>Payment Through</th>
                    <th *ngIf="this.user?.upLineName == 'Admin'">Upline Name</th>
                    <th *ngIf="downLine">Downline Name</th>
                    <th>Screenshot</th>
                    <th>IR Id</th>
                    <th>Name</th>
                    <th>Mobile No.</th>
                    <th>Email Address</th>
                    <th>Quantity</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                    <th>Order Number</th>
                    <!-- <th>Receipt</th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-i="rowIndex">
                <tr>
                    <td>{{i+1}}</td>
                    <th *ngIf="this.user?.upLineName == 'Admin'" class="text-center">
                        <div *ngIf="customer.payMode == 'offline'" class="d-flex align-items-center justify-content-evenly">
                            <ng-container *ngIf="customer.isApproved == true">
                                <span>Approved</span>
                            </ng-container>
                            <ng-container *ngIf="!(customer.isApproved == true)">
                                <i (click)="actionBTN(customer, 'approve')" class="pi pi-check" style="font-weight : 900; cursor:pointer;"></i>
                                <span (click)="actionBTN(customer, 'reject', customer.customerBookingId)" style="cursor : pointer;">X</span>
                            </ng-container>
                        </div>
                        <div *ngIf="customer.payMode == 'online'">
                            -
                        </div>
                    </th>
                    <!-- <td>{{customer?.customerBookingId}}</td> -->
                    <td>{{customer?.bookingDate | date}}</td>
                    <td>{{customer?.payMode}}</td>
                    <td>{{customer?.paymentType == '' ? 'Razorpay' : customer?.paymentType}}</td>
                    <td *ngIf="this.user?.upLineName == 'Admin'">{{customer?.upLineName}}</td>
                    <td *ngIf="downLine">{{customer?.downLineName}}</td>
                    <td *ngIf="customer?.documentPath"><a href="{{imgURL+customer?.documentPath}}" target="_blank" style="border: 1px solid #0a1847;border-radius: 5px;padding: 5px;">Document</a></td>
                    <td *ngIf="!customer?.documentPath" class="text-center">-</td>
                    <td>{{customer?.yourId}}</td>
                    <td>{{customer?.customerName}}</td>
                    <td>{{customer?.contactNumber}}</td>
                    <td>{{customer?.emailAddress}}</td>
                    <td>{{customer?.bookingQuantity}}</td>
                    <td>{{customer?.totalPrice | currency : 'INR'}}</td>
                    <td>{{customer?.status == '' ? 'Payment Success' : customer?.status}}</td>
                    <td>{{customer?.transactionalID}}</td>
                    <!-- <td><a (click)="getReceipt(customer)" style="text-decoration: underline; font-size: 12px;">get Receipt</a></td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No users found.</td>
                </tr>
            </ng-template>
    </p-table>
    </div>



    <div class="flex-grow-1"></div>
    
    <app-copyrights></app-copyrights>

</div>

<!-- <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog> -->
<!-- End Main Content Wrapper Area -->