<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}">
</p-toast>

<app-navbar-style-one></app-navbar-style-one>
<div class="parallax">
    <div class="overlay">
        <div class="container custom-container">
            <div class="modal-content" style="margin: 30px 0;">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <button class="nav-link" id="login-tab" data-bs-toggle="tab" data-bs-target="#login"
                            type="button" role="tab" aria-controls="login" aria-selected="true">Customer
                            Details</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">

                    <div class="" id="register">
                        <div class="vesax-register">
                            <!-- Register form -->
                            <form (ngSubmit)="signUp(event)" #event="ngForm">
                                <div class="row">
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Customer Name </label>
                                            <input type="text" class="form-control" name="customerName"
                                                #customerName="ngModel" [(ngModel)]="bookingData.customerName"
                                                placeholder="Enter Customer Name" required>
                                            <div *ngIf="event.submitted || customerName.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="customerName.errors?.['required']">
                                                    Name is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Your ID </label>
                                            <input type="text" class="form-control" name="yourId" #yourId="ngModel"
                                                [(ngModel)]="bookingData.yourId" placeholder="Enter ID" required>
                                            <div *ngIf="event.submitted || yourId.touched" class="alert alert-danger">
                                                <div *ngIf="yourId.errors?.['required']">
                                                    ID is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Booking Quantity </label>
                                            <input type="number" (blur)="getTotalAmount()" class="form-control" name="bookingQuantity"
                                                #bookingQuantity="ngModel" (change)="onClick($event)" [(ngModel)]="bookingData.bookingQuantity"
                                                placeholder="Enter Booking Quantity" required>
                                            <div *ngIf="event.submitted || bookingQuantity.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="bookingQuantity.errors?.['required']">
                                                    Quantity is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Email </label>
                                            <input type="text" class="form-control" name="emailAddress"
                                                #emailAddress="ngModel" pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                                [(ngModel)]="bookingData.emailAddress" placeholder="Enter Email"
                                                required>
                                            <div *ngIf="event.submitted || emailAddress.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="emailAddress.errors?.['required']">
                                                    Email is Required.
                                                </div>
                                                <div *ngIf="emailAddress.errors?.['pattern']">
                                                    Email is Invalid.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Confirm Email </label>
                                            <input type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" class="form-control"
                                                name="event_name" #confirmEmailAddress="ngModel"
                                                [(ngModel)]="bookingData.confirmEmailAddress"
                                                placeholder="Enter Confirm Email" required>
                                            <div *ngIf="event.submitted || confirmEmailAddress.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="confirmEmailAddress.errors?.['required']">
                                                    Email is Required.
                                                </div>
                                                <div *ngIf="confirmEmailAddress.errors?.['pattern']">
                                                    Email is Invalid.
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- </div> -->
                                    <!-- <ul class="nav nav-tabs" id="myTab" role="tablist" style="margin: 30px 0;">
                                    <li class="nav-item">
                                        <button class="nav-link" id="login-tab"
                                            data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab"
                                            aria-controls="login" aria-selected="true">Booking Details</button>
                                    </li>
                                </ul> -->
                                    <!-- <div class="row"> -->
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Contact Number </label>
                                            <input type="number"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                maxlength="10" class="form-control" name="contactNumber"
                                                #contactNumber="ngModel" [(ngModel)]="bookingData.contactNumber"
                                                placeholder="Enter Contact Number" required>
                                            <div *ngIf="event.submitted || contactNumber.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="contactNumber.errors?.['required']">
                                                    Number is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Postal Address </label>
                                            <input type="text" class="form-control" name="postalAddress"
                                                #postalAddress="ngModel" [(ngModel)]="bookingData.postalAddress"
                                                placeholder="Enter Address" required>
                                            <div *ngIf="event.submitted || postalAddress.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="postalAddress.errors?.['required']">
                                                    Address is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>City </label>
                                            <input type="text" class="form-control" name="city" #city="ngModel"
                                                [(ngModel)]="bookingData.city" placeholder="Enter City" required>
                                            <div *ngIf="event.submitted || city.touched" class="alert alert-danger">
                                                <div *ngIf="city.errors?.['required']">
                                                    City is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>State </label>
                                            <input type="text" class="form-control" name="state" #state="ngModel"
                                                [(ngModel)]="bookingData.state" placeholder="Enter State" required>
                                            <div *ngIf="event.submitted || state.touched" class="alert alert-danger">
                                                <div *ngIf="state.errors?.['required']">
                                                    State is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2" style="display: none;">
                                        <div class="form-group">
                                            <label>Pincode </label>
                                            <input type="number"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                maxlength="6" class="form-control" name="pinCode" #pinCode="ngModel"
                                                [(ngModel)]="bookingData.pinCode" placeholder="Enter Pincode" required>
                                            <div *ngIf="event.submitted || pinCode.touched" class="alert alert-danger">
                                                <div *ngIf="pinCode.errors?.['required']">
                                                    Pincode is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2">
                                        <div class="form-group">
                                            <label>Country </label>
                                            <select name="country" class="form-control form-select" #countryId="ngModel"
                                                id="countryId" required [(ngModel)]="bookingData.countryId">
                                                <option [value]="null">Select Country</option>
                                                <ng-container *ngFor="let item of country">
                                                    <option [value]="item.id">{{item.value}}</option>
                                                </ng-container>
                                            </select>
                                            <div *ngIf="event.submitted || countryId.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="countryId.errors?.['required']">
                                                    Country is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2">
                                        <label>Payment Mode</label>
                                        <div class="row m-0 w-100">
                                            <div class="col-md-6 col-12 d-flex align-items-center" style="width:100%">
                                                <!-- <label style="visibility: hidden;">Country </label> -->
                                                <div class="form-check mb-0">
                                                    <input class="form-check-input" value="online" required
                                                        type="radio" (change)="onPaymentClick()" name="payMode"
                                                        id="online" [(ngModel)]="bookingData.payMode">
                                                    <label class="form-check-label" for="online">
                                                        Online
                                                    </label>
                                                </div>
                                                <div class="form-check mb-0" style="margin-left: 20px;">
                                                <!-- <div class="form-check mb-0"> -->
                                                    <input class="form-check-input" value="offline" required
                                                        type="radio" name="payMode"
                                                        id="offline" (change)="onPaymentClick()" [(ngModel)]="bookingData.payMode">
                                                    <label class="form-check-label" for="offline">
                                                        Offline
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="event.submitted" class="alert alert-danger">
                                            <div *ngIf="!bookingData.payMode">
                                                Payment Mode is Required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2" *ngIf="imageCheck">
                                        <div class="form-group">
                                            <label>Payment Transferred In</label>
                                            <select name="paymentType" class="form-control form-select" #paymentType="ngModel"
                                                id="paymentType" required [(ngModel)]="bookingData.paymentType">
                                                <option value='' disabled>Please Select</option>
                                                <option value="Google Pay">Google Pay</option>
                                                <option value="UPI">UPI</option>
                                                <option value="Bank Transfer">Bank Transfer</option>
                                            </select>
                                            <div *ngIf="event.submitted || paymentType.touched"
                                                class="alert alert-danger">
                                                <div *ngIf="paymentType.errors?.['required']">
                                                    Field is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2" *ngIf="imageCheck">
                                        <div class="form-group">
                                            <label>Payment Screenshot </label>
                                            <input type="file" (change)="onImageClick($event)" class="form-control" name="documentPath" #documentPath="ngModel"
                                                [(ngModel)]="bookingData.documentPath" [required]="imageCheck">
                                            <div *ngIf="event.submitted || documentPath.touched" class="alert alert-danger">
                                                <div *ngIf="documentPath.errors?.['required']">
                                                    Screenshot is Required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 mb-2" *ngIf="imageCheck">
                                        <div class="form-group">
                                            <table class="table table-bordered">
                                                <tbody>
                                                  <tr>
                                                    <th scope="row">Google Pay / Phone Pay</th>
                                                    <td>+917976881807</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">UPI ID</th>
                                                    <td>adorntourism@ibl <br>adorntourism@axl </td>
                                                  </tr>
                                                </tbody>
                                              </table>

                                              <table class="table table-bordered">
                                                <tbody>
                                                  <tr>
                                                    <th scope="row">Bank Name</th>
                                                    <td>HDFC</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Account Type</th>
                                                    <td>Current Account</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Account Name</th>
                                                    <td>Adorn Tourism Private Limited</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Account Number</th>
                                                    <td>50200063075035</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Ifsc Code</th>
                                                    <td>HDFC0002587</td>
                                                  </tr>
                                                  <tr>
                                                    <th scope="row">Address</th>
                                                    <td>Vaishali Nagar, Ajmer, Rajasthan</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                        </div>
                                    </div>
                                </div>

                                <p-table [value]="tableData" responsiveLayout="scroll">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-data>
                                        <tr class="p-selectable-row">
                                            <td>{{data.name}}</td>
                                            <td>₹ {{data.price}}</td>
                                            <td>{{data.Quantity}}</td>
                                            <td>₹ {{data.Total}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div class="d-flex align-items-center">
                                    <div class="form-check my-0">
                                        <input class="form-check-input" type="checkbox" (click)="onChange($event)" id="flexCheckDefault">
                                        <label class="form-check-label m-0" for="flexCheckDefault">
                                          I Accept All
                                        </label>
                                      </div>
                                    <p (click)="displayModal = true" style="text-decoration: underline;cursor: pointer;margin-left: 5px;">Terms & Condition</p>
                                </div>
                                <div class="add-listings-btn">
                                    <button type="submit" class="button my-3" [ngStyle]="{'opacity': acceptTerms ? '1' : '.6' }" [disabled]="!acceptTerms">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>



<p-dialog header="Terms & Condition" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
        <ul>
            <li>Check-in Date : 07 September 2023</li>
            <li>Check-out Date : 13 September 2023</li>
            <li>Rates are per person based on 6 Nights accommodation on a double / twin sharing basis.</li>
            <li>Daily Buffet Breakfast in Hotel</li>
            <li>2 Water Bottel per day per room</li>
            <li>Hotel Check-in Time :- 02:00 pm</li>
            <li>Hotel Check-out Time :- 12:00 pm</li>
            <li>5 days (8, 9, 10, 11 & 12 September) transfer by coaches from hotel to venue.</li>
            <li>Rates not include Credit Card, Debit Card, Net Banking & UPI Merchant convenience fees.</li>
            <li>Rates are fully non-refundable.</li>
            <li>Dates are non-changable</li>
            <li>Early Check-in & Late Check-out are Subject to rooms availability with below condition:<br>
                Early Check-in between 6am - 12pm will be charged 50% from room rate.<br>
                Early Check-in before  6am on arrival date will be charged 100% from room rate.<br>
                Late Check-out until 6pm will be charged 50% from room rate.<br>
                Late Check-out after 6pm will be charge 100% from room rate<br>
            </li>
            <li>Specific Hotel will be allotted by your upline.</li>
            <li>Full payment must be cleared before check-in</li>
            <li>Hotel Name & Details will be shared, one week prior to the event.</li>
            <li>Any Loss or Damage in hotel premises penalty will be pay to the hotel directly</li>
            <li>Any additional services which is not complimentary such as Extra internet usage, extra water bottles, room services, Mini Bar etc. will be paid directly to Hotel</li>
            <li>Smoking is prohibited in all hotels... if hotel found someone guilty he/she need to pay penalty.</li>
            <li>
                If you make a booking and money is deducted, you should wait for 24 - 48hrs and if you do not receive email confirmation till then, please email us support@adorntourism.com
                    mentioning the following details: -
                <ul>
                    <li style="list-style: none;">IR ID Used for booking</li>
                    <li style="list-style: none;">Name for whom Booking was done /</li>
                    <li style="list-style: none;">Name of ISB Upline/</li>
                    <li style="list-style: none;">Date of Booking/</li>
                    <li style="list-style: none;">Time of Booking/</li>
                    <li style="list-style: none;">Amount deducted</li>
                </ul>
            </li>
                
        </ul>
        <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
        </ng-template>
</p-dialog>