import { formatPercent } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AppUtility } from 'src/app/interceptor/apputitlity';

@Component({
  selector: 'app-upline-form',
  templateUrl: './upline-form.component.html',
  styleUrls: ['./upline-form.component.scss']
})
export class UplineFormComponent implements OnInit {

  constructor(private _apiService: ApiService, private fb: FormBuilder, private router: Router, private utility: AppUtility) { }

  uplineForm = this.fb.group({
    Cond1: new FormControl('', [Validators.required]),
    downLineName: new FormControl('', [Validators.required])
  })

  displayModal: boolean = false;

  ngOnInit(): void {
    this.getUpline();
    localStorage.removeItem('otherDetail')
  }

  uplineList: any = [];
  downlineList: any = [];
  getUpline() {
    let object = {
      Mode: 'upline'
    }
    this.utility.loader(true);
    this._apiService.uplinePost(object).then((res: any) => {
      // console.log(res);
      if (res.success) {
        // this.uplineList = res.returnValue;

        // This is for previous event Magic event march

        // res.returnValue.forEach(element => {
        //   if(!(element.id == 7 || element.id == 8 || element.id == 12)) {
        //     this.uplineList.push(element);
        //   }
        // });

        // res.returnValue.forEach(element => {
        //   if(element.id >= 12) {
        //     this.uplineList.push(element);
        //   }
        // });

        // this is for current event september event
        // res.returnValue.forEach(element => {
        //   if ([1,2,12].includes(element.id)) {
        //     this.uplineList.push(element);
        //   }
        // });
        res.returnValue.forEach(element => {
          if (element.id == 11) {
            this.uplineList.push(element);
          }
        });


        // this.uplineList.splice(this.uplineList.length-2, 2);
      }
    })
    this.utility.loader(false);
  }

  submitNext(upline: NgForm) {
    // console.log(this.uplineForm.valid)
    if (this.uplineForm.valid) {
      let object = {
        upline: this.uplineForm.value?.Cond1,
        downline: this.uplineForm.value?.downLineName ?? ''
      }
      localStorage.setItem('uplineData', JSON.stringify(object));
      this.router.navigateByUrl('/registration')
    }
  }


  getDownline() {
    this.downlineList = [];
    this.uplineForm.get('downLineName').setValue('');
    if (this.uplineForm.get('Cond1').value == '3' || this.uplineForm.get('Cond1').value == '5') {
      this.uplineForm.setControl('downLineName', this.fb.control(''));
    }
    else {
      this.uplineForm.setControl('downLineName', this.fb.control('', [Validators.required]));
      let object = {
        Mode: 'downline',
        Cond1: this.uplineForm.get('Cond1').value
      }
      this.utility.loader(true);
      this._apiService.uplinePost(object).then((res: any) => {
        // console.log(res);
        if (res.success) {
          this.downlineList = res.returnValue;
        }
      })
      this.utility.loader(false);
    }
  }

}
